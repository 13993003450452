import { FC } from 'react'
import { NearbyHotel } from 'src/core/Hotel/domain/Hotel.model'
import { NearbyHotelCard } from './NearbyHotelCard'
import { useNearbyHotel } from './useNearbyHotel'

interface Props {
  id: NearbyHotel['id']
  bestPriceAverage: NearbyHotel['bestPriceAverage']
  onReserve: (hotelId: string) => void
}

export const NearbyHotelCardController: FC<Props> = ({
  id,
  bestPriceAverage,
  onReserve,
}) => {
  const hotel = useNearbyHotel({ id })

  return (
    <NearbyHotelCard
      id={id}
      bestPriceAverage={bestPriceAverage}
      hotel={hotel}
      onReserve={onReserve}
    />
  )
}
