import { FC } from 'react'
import styles from './NearbyHotels.module.scss'
import { NearbyHotel } from 'src/core/Hotel/domain/Hotel.model'
import { Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { NearbyHotelCard } from './NearbyHotelCard'
import { Flex } from 'src/ui/styles/objects/Flex'

interface Props {
  nearbyHotels: NearbyHotel[]
  onReserve: (hotelId: string) => void
}

export const NearbyHotels: FC<Props> = ({ nearbyHotels, onReserve }) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <>
      <Flex direction="column" gap="xxs">
        <Text fontStyle="xs-500" uppercase>
          {trans(
            'availability-error_not-found_alternative-hotels_subtitle-revamped',
          )}
        </Text>
        <Text fontStyle="ml-700">
          {trans(
            'availability-error_not-found_alternative-hotels_title-revamped',
            { count: nearbyHotels.length },
          )}
        </Text>
      </Flex>
      <div className={styles.container}>
        <div className={styles.hotels}>
          {nearbyHotels.map(hotel => (
            <NearbyHotelCard
              onReserve={onReserve}
              key={hotel.id}
              id={hotel.id}
              bestPriceAverage={hotel.bestPriceAverage}
            />
          ))}
        </div>
      </div>
    </>
  )
}
