import { useHotel } from 'src/ui/hooks/queries/useHotel'

interface Params {
  id?: string
}

export const useNearbyHotel = (params?: Params) => {
  return useHotel({
    ...params,
    skipAnalytics: true,
  })
}
